import { render, staticRenderFns } from "./preference-two.vue?vue&type=template&id=700a6b14&scoped=true&"
import script from "./preference-two.vue?vue&type=script&lang=js&"
export * from "./preference-two.vue?vue&type=script&lang=js&"
import style0 from "./preference-two.vue?vue&type=style&index=0&id=700a6b14&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700a6b14",
  null
  
)

export default component.exports